import React from 'react';
import { useLocation } from 'react-router-dom';

import './style.scss';

import main from "../../assets/svg/main.svg";

const Footer:React.FC = () => {
    const location = useLocation();
    const isNotHome = location.pathname !== '/';
    //console.log('location', location);

    return (
            <footer className = {`footer ${isNotHome ? 'footer__second' : ''}`}>
                <div className = 'footer__container m-width'>
                    <div className = 'main-logo'>
                        <img alt = 'logo' className = 'main-logo__img' src = { main } />
                    </div>
                </div>
            </footer>
    );
};

export default Footer;
