//Core
import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
//Components
import Header from './layout/Header';
import Content from './layout/Content';
import Footer from './layout/Footer';
//Utils
//import history from './utils/history';

const App = () => {
  return (
      <Router >
        <Header />
        <Content />
        <Footer />
      </Router>
  );
};

export default App;
