import { lazy } from 'react';
import { routeDataItemI } from "../interfaces";

const route:routeDataItemI[] = [
    {
        path:       '/',
        exact:      true,
        components: lazy(() => import('../pages/Home'))
    },
    {
        path:       '/about',
        components: lazy(
            () => import('../pages/About')
        ),
    },
    {
        path:       '/add-inheritance',
        components: lazy(
            () => import('../pages/AddInheritance')
        ),
    },
    {
        path:       '/inheritance/:id',
        components: lazy(
            () => import('../pages/Inheritance')
        ),
    }
];

export default route;
