import React from 'react';
import './style.scss';
import { loaderI } from '../../interfaces';

export const Loader:React.FC<loaderI> = ({
    colorCircle='#E6EBE6',
    colorPart='#5B5AFF',
}) => {

    return (
        <div className = 'loader-is loader--style1' title = '0'>
            <p>loader {colorCircle}, {colorPart}</p>
        </div>
    );
};
