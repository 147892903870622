import React from 'react';
import { Link } from "react-router-dom";
import './style.scss';
//import { useLocation } from "react-router-dom";

import main from "../../assets/svg/main.svg";
import { Btn } from "../../components/Btn";

const Header: React.FC = () => {

    //const location = useLocation();

    return (
        <header
            className={` header `}>
            <div
                className={`header__container m-width`}>

                <Link to='/' className='main-logo' >
                    <img alt='logo' className='main-logo__img' src={main} />
                </Link>
                {/*
                 <div className='header__wrap-btn'>
                    <Btn
                        full
                        theme = 'transp-black'
                        title = { 'Додати спадок' }
                        url={'/add-inheritance'}
                        iconName='plus'
                    />
                </div> 
                */}
            </div>
        </header>
    );
};

export default Header;
