import React, { Suspense } from 'react';
import { Switch, Route, Redirect } from "react-router-dom";
import routes from '../../router';
import './style.scss';
import { Loader } from "../../components/Loader";
import { useLocation } from 'react-router-dom';

const Content:React.FC = () => {
    const location = useLocation();
    const isHome = location.pathname === '/';
    return (
        <main className = {`container ${isHome ? 'container--home' : ''}`}>
            <Suspense fallback = { <div className = 'loading'><Loader /></div> }>
                <Switch>
                    <Redirect exact from = '/inheritance' to = '/' />
                    {
                        routes.map((route, index) => (
                            <Route
                                exact = { route.exact }
                                key = { index }
                                path = { route.path }
                                render = { (props) => (
                                    // @ts-ignore
                                    <route.components { ...props } routes = { route?.routes } />
                                ) }
                            />

                        ))
                    }
                </Switch>
            </Suspense>
        </main>
    );
};

export default Content;
